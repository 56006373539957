<template>
  <app-layout classes="settings">
    <div class="settings__head mt-2 mt-md-3 p-2 p-md-3" v-if="user">
      <img
        class="settings__head-avatar"
        :src="user.user.avatar"
        :alt="`${user.user.full_name} avatar`"
        width="128"
        height="128"
      />
      <h1 class="settings__head-name">
        {{ user.user.full_name }}
      </h1>
      <div>
        Baasje van {{ user.active_pet.name }}
      </div>
    </div>

    <div class="p-2 p-md-3">
      <ul class="settings-list is-first">
        <li class="mb-2">
          <search-result
            title="Profiel wijzigen"
            icon="user"
            :to="{ name: 'SettingsProfile' }"
          />
        </li>

        <li class="mb-2">
          <search-result
            title="Wachtwoord wijzigen"
            icon="shield"
            :to="{ name: 'SettingsPassword' }"
          />
        </li>

        <li class="mb-2">
          <search-result
            title="Meldingen"
            icon="notification"
            :to="{ name: 'SettingsNotifications' }"
          />
        </li>
      </ul>

      <ul class="settings-list">
        <li class="mb-2">
          <search-result
            title="Over Kwispelcoach"
            icon="info"
            :to="{ name: 'About' }"
          />
        </li>

        <li class="mb-2">
          <search-result
            title="Privacy policy"
            icon="shield"
            :to="{ name: 'PrivacyPolicy' }"
          />
        </li>

        <li class="mb-2">
          <search-result
            title="Algemene voorwaarden"
            icon="file"
            :to="{ name: 'Agreement' }"
          />
        </li>
      </ul>

      <ul class="settings-list">
        <li class="mb-2">
          <search-result
            component-type="button"
            title="Uitloggen"
            icon="logout"
            @click="onLogout"
          />
        </li>
      </ul>
    </div>
  </app-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from './AppLayout.vue';
import SearchResult from '../components/SearchResult.vue';

export default {
  components: {
    SearchResult,
    AppLayout,
  },

  methods: {
    onLogout() {
      return this.$store.dispatch('logout')
        .then(() => {
          this.$router.push({ name: 'Login' });
        });
    },
  },

  computed: {
    ...mapGetters([
      'user',
    ]),
  },
};
</script>

<style>
.app-layout.settings {
  background-color: #fff;
}

.settings__head {
  text-align: center;
}

.settings__head-avatar {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  display: block;
  margin: 0 auto 1rem;
}

.settings__head-name {
  font-size: 1.25rem;
  margin-bottom: .25rem;
}

.settings-list {
  border-top: 1px solid #f3f3f4;
  padding-top: 1rem;
}
</style>
